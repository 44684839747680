<template>
  <component v-bind:is="layout_component" :elements="elements"></component>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  VBPopover,
} from "bootstrap-vue";

const axios = require("axios");


export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VBPopover,

    'DefaultViewLayout' : () => import("@/views/brunacomponents/bruna_view_layouts/DefaultViewLayout.vue"),
  },

  directives: {
    "b-popover": VBPopover,
  },
 
  data: function () {
    return {
      layout_component: "DefaultViewLayout",
      id: null,
      elements: [],
    };
  },
  created() {


    this.getData().then((response) => {
      const { id, layout_component, elements } = response.data.data;

      console.log(elements)

      this.id = id;
      this.layout_component = layout_component;
      this.elements = elements;


    });
  },

  mounted() { 
   
  },

  methods: {
    getData() {
      return axios.request({
        method: "get",
        url: "http://camanchaca.localhost:62907/api/view/4d5a23e2-c3f1-4656-8c7b-fc8a624ccc27",
        headers: {
          Accept: "application/json",
        },
      });
    },



  },
};
</script>

<style lang="scss"></style>
