<template>
  <b-row class="match-height">
    <b-col md="12">
      <bruna-view></bruna-view>
    </b-col>
  </b-row>
</template>
  
<script>
import {
  BRow,  
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody, 
  BCard,
  VBPopover,
} from "bootstrap-vue";
 
import BrunaView from "@/views/brunacomponents/BrunaView.vue";

export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VBPopover, 
    BrunaView,
  },

  directives: {
    "b-popover": VBPopover,
  },

  data: function () {
    return {};
  },
  created() {},  

  mounted() {
    /*
    var channel = this.$pusher.subscribe('charts');
    channel.bind('charts', function(data) {
        console.log(data);

    });        
        */


       
  },

  methods: {},
};
</script>

<style lang="scss"></style>
